import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryParamGlobalConfig } from '../../../config/query-param-global.config';
import { Base64Util } from '../../utils/base64.util';
import { map } from 'rxjs/operators';
import { ApiData } from '../../../classes/api-data';
import { ApiDataService } from '../../services/api-data.service';
import { apiConfig } from '../../../config/api.config';
import { IQueryParams } from '../../../classes/query-params-config.interface';

/** Http Service Layer */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  apiData: ApiData;

  constructor(
    private http: HttpClient,
    private apiDataService: ApiDataService
  ) {}

  /** generate url for backend call */
  generateFullUrl(url: string, apiUrl = apiConfig.baseUrl.default): string {
    return apiUrl + url;
  }

  /** generate url query params for backend call */
  generateUrlQueryParams(urlParams: IQueryParams = null): HttpParams {
    let params = new HttpParams();

    if (urlParams) {
      if (QueryParamGlobalConfig.useBase64Encode.backend) {
        params = params.append(
          QueryParamGlobalConfig.useBase64Encode.queryParamsUrlKey,
          Base64Util.encode(urlParams)
        );
      } else {
        Object.keys(urlParams).forEach((property: string) => {
          if (property in urlParams) {
            params = params.append(property, urlParams[property] as string);
          }
        });
      }
    }

    return params;
  }

  get<ResponseType = unknown>(
    url: string,
    urlParams: IQueryParams = null,
    apiUrl = apiConfig.baseUrl.default
  ): Observable<ResponseType> {
    return this.http
      .get<ResponseType>(this.generateFullUrl(url, apiUrl), {
        params: this.generateUrlQueryParams(urlParams),
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const apiVersion = response.headers.get('api-version') ?? '';
          const apiEnv = response.headers.get('api-env') ?? '';

          if (this.apiData?.apiVersion !== apiVersion || this.apiData?.apiEnv !== apiEnv) {
            this.apiDataService.setApiData({ apiVersion, apiEnv });
          }

          return response.body;
        })
      );
  }

  post<ResponseType = unknown, DataType = ResponseType>(
    url: string,
    data: DataType,
    apiUrl = apiConfig.baseUrl.default
  ): Observable<ResponseType> {
    return this.http.post<ResponseType>(this.generateFullUrl(url, apiUrl), data);
  }

  put<ResponseType = unknown, DataType = ResponseType>(
    url: string,
    data: DataType,
    apiUrl = apiConfig.baseUrl.default
  ): Observable<ResponseType> {
    return this.http.put<ResponseType>(this.generateFullUrl(url, apiUrl), data);
  }

  patch<ResponseType = unknown, DataType = ResponseType>(
    url: string,
    data: DataType,
    apiUrl = apiConfig.baseUrl.default
  ): Observable<ResponseType> {
    return this.http.patch<ResponseType>(this.generateFullUrl(url, apiUrl), data);
  }

  delete<ResponseType = unknown, DataType = ResponseType>(
    url: string,
    apiUrl = apiConfig.baseUrl.default,
    data?: DataType
  ): Observable<ResponseType> {
    return this.http.delete<ResponseType>(this.generateFullUrl(url, apiUrl), data);
  }

  sendRequest<ResponseType = unknown>(request: HttpRequest<ResponseType>): Observable<HttpEvent<ResponseType>> {
    return this.http.request<ResponseType>(request);
  }
}
