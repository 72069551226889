import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from './modules/auth/auth.module';
import { WithTestIdDirective } from './directives/test-id.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, AuthModule, MatSnackBarModule],
  declarations: [WithTestIdDirective],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AuthModule,
    WithTestIdDirective,
  ],
  providers: [],
})
export class SharedModule {}
