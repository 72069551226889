import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiData } from '../../classes/api-data';

@Injectable({
  providedIn: 'root',
})
export class ApiDataService {
  private apiDataSubject: BehaviorSubject<ApiData> = new BehaviorSubject<ApiData>(null);

  setApiData(apiData: ApiData): void {
    this.apiDataSubject.next(apiData);
  }

  getApiData(): BehaviorSubject<ApiData> {
    return this.apiDataSubject;
  }
}
