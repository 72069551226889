import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthGuardParams } from './classes/auth-guard-params';
import { AuthUtil } from './utils/auth.util';
import { UserRole } from '../../../classes/user/user-role';
import { DebugHelperUtil } from '../../utils/debug-helper.util';

@Injectable()
export class AuthGuard {
  constructor(private auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (route.data && route.data.authGuardParams) {
        const authGuardParams = route.data.authGuardParams as AuthGuardParams;
        const userRoles = this.auth.getUserRoles();
        let denied = false;

        this.logGuardParameters(state.url, authGuardParams, userRoles);

        if (!this.checkAuthGuardParams(authGuardParams)) {
          denied = true;
        }

        this.checkForUserRoles(authGuardParams, userRoles, () => {
          this.redirectToHomeByRole(authGuardParams, state.url);
          denied = true;
        });

        this.checkForPermission(authGuardParams, userRoles, () => {
          this.redirectToHomeByRole(authGuardParams, state.url);
          denied = true;
        });

        if (!denied) {
          this.log('PERMISSION GRANTED');
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        console.error('Missing authGuardParams');
        resolve(false);
      }
    });
  }

  checkForUserRoles(authGuardParams: AuthGuardParams, userRoles: UserRole[], callback: () => void) {
    if (authGuardParams.rolesDisabled) {
      if (AuthUtil.isContainUserRoles(authGuardParams.rolesDisabled, this.auth.userRolesByKey)) {
        this.log('PERMISSION DENIED -> role IN rolesDisabled');
        callback();
      }
    } else if (authGuardParams.rolesEnabled) {
      if (!AuthUtil.isContainUserRoles(authGuardParams.rolesEnabled, this.auth.userRolesByKey)) {
        this.log('PERMISSION DENIED -> role NOT IN rolesEnabled');
        callback();
      }
    }
  }

  checkForPermission(authGuardParams: AuthGuardParams, userRoles: UserRole[], callback: () => void) {
    if (authGuardParams.permissionDisabled) {
      if (AuthUtil.isContainUserPermission(authGuardParams.permissionDisabled, userRoles)) {
        this.log('PERMISSION DENIED -> permission IN permissionDisabled');
        callback();
      }
    } else if (authGuardParams.permissionEnabled) {
      if (!AuthUtil.isContainUserPermission(authGuardParams.permissionEnabled, userRoles)) {
        this.log('PERMISSION DENIED -> permission NOT IN permissionEnabled');
        callback();
      }
    }
  }

  checkAuthGuardParams(authGuardParams: AuthGuardParams): boolean {
    if (authGuardParams.rolesDisabled && authGuardParams.rolesEnabled) {
      console.error('Cant have rolesDisabled and rolesEnabled too!');
      return false;
    }
    if (authGuardParams.permissionDisabled && authGuardParams.permissionEnabled) {
      console.error('Cant have permissionDisabled and permissionEnabled too!');
      return false;
    }
    if (
      (authGuardParams.rolesDisabled || authGuardParams.rolesEnabled) &&
      (authGuardParams.permissionDisabled || authGuardParams.permissionEnabled)
    ) {
      console.error('Cant have role and permission restrictions too!');
      return false;
    }

    return true;
  }

  redirectToHomeByRole(authGuardParams: AuthGuardParams, previousUrl: string) {
    this.log('redirect to home page');
    this.auth.redirectToHomeByRole(authGuardParams, previousUrl);
  }

  private logGuardParameters(url: string, authGuardParams: AuthGuardParams, userRoles: UserRole[]) {
    this.log('----------- HANDLE ROUTE --------------');
    this.log('url: ', url);
    this.log('authGuardParams: ', authGuardParams);
    this.log('userRoles: ', userRoles);
    this.log('userRolesByKey: ', this.auth.userRolesByKey);
  }

  private log(...args: unknown[]) {
    DebugHelperUtil.logIfDebug('AUTH GUARD', args);
  }
}
