import { environment } from '../../environments/environment';
import { ApiConfig } from '../classes/api-config-interface';

export const apiConfig: ApiConfig = {
  url: {
    authRefresh: 'auth/login/refresh',
    authLogin: 'auth/login/email',
    authSocialLogin: 'auth/login/social',
    authLogout: 'auth/logout',
    authLoginFirebase: 'auth/login/firebase',

    userForgotPassword: 'users/forgot-password',
    userResetPassword: 'users/reset-password',
    userChangePassword: 'users/change-password',

    userMe: 'users/me',
  },
  baseUrl: {
    default: environment.apiUrl,
    testDev: environment.testDevApiUrl,
  },
};
