import { UserRole } from '../classes/user/user-role';

export class AuthConfig {
  static readonly TOKEN_KEY = 'accessToken';
  static readonly REFRESH_TOKEN_KEY = 'refreshToken';

  static readonly CSRF_TOKEN_KEY = 'CSRF-TOKEN';
  static readonly CSRF_TOKEN_HEADER_KEY = 'x-csrf-token';

  static readonly COOKIE_TOKEN_KEY = 'access_token';
  static readonly COOKIE_REFRESH_TOKEN_KEY = 'refresh_token';

  static readonly AUTHORIZATION_HEADER_KEY = 'Authorization';
  static readonly TOKEN_STORAGE_KEY = 'auth-user-token';
  static readonly REFRESH_TOKEN_STORAGE_KEY = 'auth-user-refresh-token';

  static readonly DEFAULT_USER_ROLE = UserRole.SystemAdmin;
}
