import { UserRole, UserRoleMap } from '../../../../classes/user/user-role';
import { UserPermission } from '../../../../classes/user/user-permission';
import { UserRolePermission } from '../../../../classes/user/user-role-permission';

export class AuthUtil {
  static isContainUserRoles(routeEnabledDisabledRoles: UserRole[], userRolesByKey: UserRoleMap): boolean {
    return (routeEnabledDisabledRoles || []).some((role) => userRolesByKey[role]);
  }

  // TODO: get permissions from Backend, map them to object keys
  static isContainUserPermission(routeEnabledDisabledPermissions: UserPermission[], userRoles: UserRole[]): boolean {
    return (routeEnabledDisabledPermissions || []).some((permission) => {
      return userRoles.some((userRole) => UserRolePermission[userRole]?.indexOf(permission) !== -1);
    });
  }
}
