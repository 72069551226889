import { IQueryParams } from '../../classes/query-params-config.interface';

export class Base64Util {
  static encode(params: unknown): string {
    let obj = null;
    if (!params) {
      return obj;
    }

    try {
      obj = btoa(JSON.stringify(params));
    } catch (error) {
      console.warn(`Base64 encode failed`, error);
    }

    return obj;
  }

  static decode(param: string): IQueryParams | null {
    let obj: IQueryParams = null;
    if (!param) {
      return obj;
    }

    try {
      const decoded = atob(param);
      obj = JSON.parse(decoded) as IQueryParams;
    } catch (error) {
      console.warn(`Base64 decode failed`, error);
    }

    return obj;
  }
}
