// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { IEnvironment } from './classes/IEnvironment';
import cspDefault from './classes/CspDefault';
import { CspService } from '../app/shared/services/csp.service';

export const environment: IEnvironment = {
  name: 'LOCAL',
  production: false,

  apiUrl: 'https://api.auth-demo.attrecto-dev.com/api/',
  testDevApiUrl: 'https://test-dev.intra.attrecto.com/property/api',

  userManagerApi: 'http://localhost:3002',

  lowResolutionEnabled: true,
  useCookieAuth: true,
  logEntities: false,

  cookieDomain: 'auth-demo.attrecto-dev.com',
  cookieServeUrl: 'frontend.auth-demo.attrecto-dev.com',
  cookieServeMode: 'https',

  gapi: {
    // Please do not use this clientId in your application, this is only for test purposes
    clientId: '701173739879-ius1g0bbjd2j30jruhffjikcnsl4po6d.apps.googleusercontent.com',
    scopes: 'profile email',
  },
  facebook: {
    // Please do not use this appId in your application, this is only for test purposes
    appId: '582687702823312',
    scopes: 'public_profile,email',
  },
  firebase: {
    apiKey: 'AIzaSyBaEtwHSMIv4mKpo_T7Y340-IX5tGos9w8',
    authDomain: 'authdemo-f8bc7.firebaseapp.com',
    projectId: 'authdemo-f8bc7',
    storageBucket: 'authdemo-f8bc7.appspot.com',
    messagingSenderId: '701173739879',
    appId: '1:701173739879:web:1e90c5aa9a5f971798bfc2',
  },

  google: {
    mapApiKey: 'AIzaSyCpO6_PaKzxLv8imSvPzGZ9_a-GPxfCtNk',
  },

  apple: {
    // Please do not use these parameters in your application, these are only for test purposes
    clientId: 'com.attrecto.starter',
    redirectURL: 'https://api.auth-demo.attrecto-dev.com/api/auth/callback/apple',
    scope: 'name email',
    loginOrigin: 'https://api.auth-demo.attrecto-dev.com',
  },

  keyCloakEnabled: false,
  keyCloak: {
    url: 'https://test-dev.intra.attrecto.com/auth/',
    realm: 'UAR',
    clientId: 'angular-test',
  },

  csp: CspService.mergeCsp(cspDefault, {
    'script-src': [
      new URL('https://apis.google.com'),
      new URL('https://connect.facebook.net'),
      new URL('https://accounts.google.com'),
      new URL('https://authdemo-f8bc7-default-rtdb.firebaseio.com'),
      new URL('https://maps.googleapis.com'),
    ],
    'connect-src': [
      new URL('https://graph.facebook.com'),
      new URL('https://accounts.google.com'),
      new URL('https://securetoken.googleapis.com'),
      new URL('https://www.facebook.com'),
      new URL('https://web.facebook.com'),
      new URL('https://z-p3-graph.facebook.com/'),
      new URL('https://identitytoolkit.googleapis.com'),
      new URL('https://test-dev.intra.attrecto.com'),
      new URL('https://frontend.auth-demo.attrecto-dev.com'),
      new URL('https://firebasestorage.googleapis.com'),
      new URL('https://test-dev.intra.attrecto.com'),
      new URL('ws://localhost:4200'),
      new URL('https://maps.googleapis.com'),
      new URL('http://localhost:3002'),
    ],
    'style-src': [new URL('https://accounts.google.com'), new URL('https://fonts.googleapis.com')],
    'img-src': [
      new URL('https://www.facebook.com'),
      new URL('https://web.facebook.com'),
      new URL('https://maps.gstatic.com/'),
      new URL('https://lh3.googleusercontent.com/'),
      new URL('https://mui.com'),
      new URL('https://cloudflare-ipfs.com'),
    ],
    'frame-src': [
      new URL('https://accounts.google.com'),
      new URL('https://authdemo-f8bc7.firebaseapp.com'),
      new URL('https://test-dev.intra.attrecto.com'),
      new URL('https://frontend.auth-demo.attrecto-dev.com'),
      new URL('https://localhost:4200'),
      new URL('http://localhost:4200'),
      new URL('http://localhost:3002'),
    ],
    'font-src': [new URL('https://fonts.gstatic.com')],
  }),
};
