import { JwtToken } from '../../../../classes/jwt-token';

export class JwtHelper {
  decodeToken(token: string): JwtToken {
    if (!token || token === '') {
      return null;
    }

    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error("The inspected token doesn't appear to be a JWT. Check to make sure it has three parts.");
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token.');
    }

    return JSON.parse(decoded) as JwtToken;
  }

  isTokenExpired(jwtToken: JwtToken, offsetSeconds?: number): boolean {
    const date = this.getTokenExpirationDate(jwtToken);
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return false;
    }

    const dateLimit = new Date().valueOf() + offsetSeconds * 1000;
    return dateLimit <= date.valueOf();
  }

  getTokenExpirationDate(jwtToken: JwtToken): Date | null {
    if (!jwtToken.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(jwtToken.exp);

    return date;
  }

  private urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw new Error('Illegal base64url string!');
    }

    try {
      return this.b64DecodeUnicode(output);
    } catch (err) {
      return atob(output);
    }
  }

  private b64DecodeUnicode(str: string): string {
    return decodeURIComponent(
      atob(str).replace(/(.)/g, (m: string, p: string) => {
        let code = p.charCodeAt(0).toString(16).toUpperCase();
        if (code.length < 2) {
          code = '0' + code;
        }
        return '%' + code;
      })
    );
  }
}
