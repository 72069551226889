import { IEnvironment } from './IEnvironment';

export type ICspDirective = {
  [name in CspDirectiveName]: ICspSource[];
};
export type IEnvironmentKey = keyof IEnvironment;

export type ICspSource = CspSourceType | URL | IEnvironmentKey;

export type CspDirectiveName =
  | 'default-src'
  | 'script-src'
  | 'style-src'
  | 'connect-src'
  | 'img-src'
  | 'frame-src'
  | 'font-src';

export enum CspSourceType {
  UnsafeInline = "'unsafe-inline'",
  UnsafeEval = "'unsafe-eval'",
  Self = "'self'",
  Data = 'data:',
}
