import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRole } from '../../../../classes/user/user-role';
import { AuthUtil } from '../utils/auth.util';
import { AuthService } from '../auth.service';

@Directive({
  selector: '[appUserHasRole], [appUserExceptRole]',
})
export class UserHasRoleDirective {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private auth: AuthService
  ) {}

  @Input() set appUserHasRole(userRoles: UserRole[]) {
    if (AuthUtil.isContainUserRoles(userRoles, this.auth.userRolesByKey)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  @Input() set appUserExceptRole(userRoles: UserRole[]) {
    if (!AuthUtil.isContainUserRoles(userRoles, this.auth.userRolesByKey)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
