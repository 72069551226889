import { CspSourceType, ICspDirective } from './ICsp';

let CspDefault: ICspDirective = {
  'default-src': [CspSourceType.Self],
  'script-src': [CspSourceType.Self],
  'style-src': [CspSourceType.Self, CspSourceType.UnsafeInline],
  'img-src': [CspSourceType.Self, CspSourceType.Data],
  'font-src': [CspSourceType.Self, CspSourceType.Data],
  'frame-src': [],
  'connect-src': ['apiUrl'],
};

export default CspDefault;
