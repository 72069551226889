import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../http/http.service';
import { User } from '../../../classes/user/user';
import { UserRole, UserRoleMap } from '../../../classes/user/user-role';
import { JwtToken } from '../../../classes/jwt-token';
import { AuthGuardParams } from './classes/auth-guard-params';
import { environment } from '../../../../environments/environment';
import { CookieStorageService } from './services/cookie.storage.service';
import { AuthConfig } from '../../../config/auth.config';
import { LocalStorageService } from './services/local.storage.service';
import { AppUrlConfig } from '../../../config/app-url.config';
import { apiConfig } from '../../../config/api.config';
import { LoginCookieResponse } from './classes/login-cookie-response';
import { LoginHeaderResponse } from './classes/login-header-response';
import { JwtHelper } from './utils/jwt-helper.util';
import { DebugHelperUtil } from '../../utils/debug-helper.util';

/** Write/read data of user's to/from local storage, */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedInUser: User;
  loggedInUserChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  previousURL: string;
  jwtHelper = new JwtHelper();

  userRolesByKey: UserRoleMap = {};

  constructor(
    private httpService: HttpService,
    private router: Router,
    private injector: Injector,
    private cookieService: CookieStorageService,
    private localStorageService: LocalStorageService
  ) {}

  redirectToPreviousUrlOrHome() {
    // need to inject router here because if you put it in the constructor, the app initializer will failed to load
    if (this.previousURL && this.previousURL !== AppUrlConfig.LOGIN_URL) {
      const splitUrl = this.previousURL.split('?');
      const route = splitUrl[0];
      const { queryParams } = this.router.parseUrl(this.previousURL);

      this.router.navigate([route], { queryParams });
      this.previousURL = null;
    } else {
      this.router.navigate([AppUrlConfig.HOME_URL]);
    }
  }

  logout(doRedirect = false) {
    this.log('logout');

    if (this.getAuthToken()) {
      this.logoutWithApi(doRedirect);
    } else {
      this.logoutClient(doRedirect);
    }
  }

  private initUserRolesByKey(userRoles: UserRole[] = []): void {
    this.userRolesByKey = {
      [UserRole.Unauthorized]: userRoles.length === 0,
    };

    for (const role of userRoles) {
      this.userRolesByKey[role] = true;
    }

    this.log('user roles by keys init done: ', this.userRolesByKey);
  }

  handleLoginResponse(response: LoginHeaderResponse | LoginCookieResponse) {
    if (!environment.useCookieAuth) {
      this.setTokensToLocalStorage(response as LoginHeaderResponse);
    }

    this.loadUser().subscribe((result: User) => {
      this.setUser(result);

      this.redirectToPreviousUrlOrHome();
    });
  }

  setTokensToLocalStorage(response: LoginHeaderResponse): void {
    this.localStorageService.setItem(AuthConfig.TOKEN_STORAGE_KEY, response[AuthConfig.TOKEN_KEY]);
    this.localStorageService.setItem(AuthConfig.REFRESH_TOKEN_STORAGE_KEY, response[AuthConfig.REFRESH_TOKEN_KEY]);
  }

  private logoutWithApi(doRedirect: boolean) {
    // when using the header version the refreshToken has to be sent in the body so the BE can invalidate it
    this.httpService
      .post(
        apiConfig.url.authLogout,
        environment.useCookieAuth
          ? {}
          : { refreshToken: this.localStorageService.getItem(AuthConfig.REFRESH_TOKEN_STORAGE_KEY) }
      )
      .subscribe({
        next: () => this.logoutClient(doRedirect),
        error: () => this.logoutClient(doRedirect),
      });
  }

  private logoutClient(doRedirect: boolean) {
    if (environment.useCookieAuth) {
      this.logoutCookie(doRedirect);
    } else {
      this.logoutLocalStorage(doRedirect);
    }
  }

  private logoutLocalStorage(doRedirect: boolean) {
    this.setTokenToLocalStorage(null);
    this.setUser(null);

    if (doRedirect) {
      this.redirectToLogin();
    }
  }

  private logoutCookie(doRedirect: boolean) {
    this.cookieService.removeAll();
    this.setUser(null);

    if (doRedirect) {
      this.redirectToLogin();
    }
  }

  loadUser(): Observable<User> {
    this.log('load user');
    return this.httpService.get(apiConfig.url.userMe);
  }

  checkAuth(): Promise<void> {
    this.log('check auth');

    const pureToken = this.getAuthToken();

    return new Promise((resolve) => {
      if (this.validateJwtToken(pureToken)) {
        this.loadUser().subscribe({
          next: (userData: User) => {
            this.setUser(userData);
            resolve();
          },
          error: () => {
            this.log('load user failed');
            this.logout();
            resolve();
          },
        });
      } else {
        this.log('token invalid');
        this.logout();
        resolve();
      }
    });
  }

  redirectToHomeByRole(authGuardParams: AuthGuardParams, previousUrl: string) {
    let route;
    const userRoles = this.getUserRoles();

    if (authGuardParams.redirectTo) {
      route = authGuardParams.redirectTo;
    } else if (userRoles.indexOf(UserRole.Unauthorized) === -1) {
      route = AppUrlConfig.HOME_URL;
      // extend navigate logic by role
      // if (userRole === UserRole.ADMIN) {
      //   route = '/admin-home';
      // }
    } else {
      route = AppUrlConfig.LOGIN_URL;
    }

    this.previousURL = previousUrl;
    this.router.navigate([route]);
  }

  redirectToLogin() {
    const router = this.injector.get<Router>(Router);
    router.navigate([AppUrlConfig.LOGIN_URL]);
  }

  getLoggedInUser(): User {
    return this.loggedInUser;
  }

  getAuthToken(): string {
    if (environment.useCookieAuth) {
      return this.cookieService.getItem(AuthConfig.COOKIE_TOKEN_KEY);
    } else {
      return this.localStorageService.getItem(AuthConfig.TOKEN_STORAGE_KEY);
    }
  }

  getUserRoles(): UserRole[] {
    let roles = [UserRole.Unauthorized];

    if (Array.isArray(this.loggedInUser?.roles)) {
      roles = this.loggedInUser.roles;
    }

    return roles;
  }

  private validateJwtToken(token: string): boolean {
    const jwtToken = this.getDecodedToken(token);

    return !(!jwtToken || !jwtToken.sub || !jwtToken.exp);
  }

  getDecodedToken(token: string): JwtToken {
    try {
      return this.jwtHelper.decodeToken(token);
    } catch (error) {
      console.log('error while parsing token: ', error);
      return null;
    }
  }

  setTokenToLocalStorage(token: string | null) {
    if (token !== null) {
      this.localStorageService.setItem(AuthConfig.TOKEN_STORAGE_KEY, token);
    } else {
      this.localStorageService.removeItem(AuthConfig.TOKEN_STORAGE_KEY);
    }
  }

  setUser(userData: User | null) {
    this.log('set user: ', userData);

    if (userData) {
      if (!userData.roles) {
        userData.roles = [AuthConfig.DEFAULT_USER_ROLE];
      }
    }
    this.initUserRolesByKey(userData?.roles);

    this.loggedInUser = userData;
    this.loggedInUserChange.next(this.loggedInUser);
  }

  private log(...args: unknown[]) {
    DebugHelperUtil.logIfDebug('AUTH SERVICE', args);
  }
}
