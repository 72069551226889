import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[appWithTestId]',
})
export class WithTestIdDirective implements OnInit {
  @Input() testIdPostfix: string; // Custom Post-fix ID
  @Input() testIdName: string; //Button color type or formControlName etc

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private route: ActivatedRoute
  ) {}

  private generateTestId(): string {
    const pageName = this.route.snapshot.root?.firstChild?.routeConfig?.path;
    const componentType = this.route.component?.name;

    this.testIdName = this.testIdName ? `_${this.testIdName}` : '';
    this.testIdPostfix = this.testIdPostfix ? `_${this.testIdPostfix}` : '';

    return `${pageName}_page_${componentType}${this.testIdName}${this.testIdPostfix}`;
  }

  ngOnInit() {
    this.renderer.setAttribute(this.elRef.nativeElement, 'data-test-id', this.generateTestId());
  }
}
